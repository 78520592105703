exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactlicense-js": () => import("./../../../src/pages/contactlicense.js" /* webpackChunkName: "component---src-pages-contactlicense-js" */),
  "component---src-pages-free-js": () => import("./../../../src/pages/free.js" /* webpackChunkName: "component---src-pages-free-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-more-js": () => import("./../../../src/pages/more.js" /* webpackChunkName: "component---src-pages-more-js" */),
  "component---src-pages-osm-js": () => import("./../../../src/pages/osm.js" /* webpackChunkName: "component---src-pages-osm-js" */),
  "component---src-pages-pay-js": () => import("./../../../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-paypoints-js": () => import("./../../../src/pages/paypoints.js" /* webpackChunkName: "component---src-pages-paypoints-js" */),
  "component---src-pages-point-checkout-js": () => import("./../../../src/pages/pointCheckout.js" /* webpackChunkName: "component---src-pages-point-checkout-js" */),
  "component---src-pages-points-js": () => import("./../../../src/pages/points.js" /* webpackChunkName: "component---src-pages-points-js" */),
  "component---src-pages-pointsuccess-js": () => import("./../../../src/pages/pointsuccess.js" /* webpackChunkName: "component---src-pages-pointsuccess-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-registerlogin-js": () => import("./../../../src/pages/registerlogin.js" /* webpackChunkName: "component---src-pages-registerlogin-js" */),
  "component---src-pages-student-registration-js": () => import("./../../../src/pages/student-registration.js" /* webpackChunkName: "component---src-pages-student-registration-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

