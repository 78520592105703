import React, { createContext, useState, useEffect } from "react";
import { getUserData, getCurrentUserEmail } from "../components/Firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userName, setUserName] = useState(null);
  const [userInitials, setUserInitials] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userCountry, setUserCountry] = useState(null);
  const [userZipCode, setUserZipCode] = useState(null);
  const [pointBalance, setPointBalance] = useState(0); // Initialize to 0 instead of null
  const [userCustomerId, setUserCustomerId] = useState(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const auth = getAuth();

  // Helper function to reset all user data
  const resetUserData = () => {
    setUserName(null);
    setUserInitials(null);
    setUserEmail(null);
    setUserCountry(null);
    setUserZipCode(null);
    setPointBalance(0);
    setUserCustomerId(null);
  };

  const fetchUserData = async () => {
    try {
      const {
        name,
        initials,
        country,
        zipCode,
        customerId,
        PointBalance,
      } = await getUserData();

      setUserName(name);
      setUserInitials(initials);
      setUserCountry(country);
      setUserZipCode(zipCode);
      setUserCustomerId(customerId);
      setPointBalance(PointBalance || 0); // Ensure defaults to 0 if null/undefined
      setUserEmail(await getCurrentUserEmail());
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Reset to defaults on error
      resetUserData();
    }
  };

  const loadGuestData = () => {
    // Load guest data from sessionStorage
    const guestEmail = sessionStorage.getItem('guestEmail');
    const customerId = sessionStorage.getItem('customerId');
    
    if (guestEmail) {
      setUserEmail(guestEmail);
    }
    if (customerId) {
      setUserCustomerId(customerId);
    }
    // Always ensure pointBalance is 0 for guests
    setPointBalance(0);
  };

  const refreshUserContext = async () => {
    if (auth.currentUser) {
      resetUserData(); // Clear existing data first
      await fetchUserData();
    } else {
      resetUserData(); // Clear existing data first
      loadGuestData();
    }
  };

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // Reset all user data first, regardless of whether they're logging in or out
      resetUserData();
      
      if (user) {
        setIsUserLoggedIn(true);
        fetchUserData(); // Fetch user data when signed in
      } else {
        setIsUserLoggedIn(false);
        loadGuestData(); // Load guest data when not signed in
      }
    });

    // Initially check for guest data if no user is logged in
    if (!auth.currentUser) {
      resetUserData();
      loadGuestData();
    }

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []); // Empty dependency array to set up the listener only once

  return (
    <UserContext.Provider
      value={{
        userName,
        setUserName,
        userInitials,
        setUserInitials,
        userEmail,
        setUserEmail,
        userCountry,
        setUserCountry,
        userZipCode,
        setUserZipCode,
        pointBalance,
        setPointBalance,
        userCustomerId,
        setUserCustomerId,
        isUserLoggedIn,
        setIsUserLoggedIn,
        refreshUserContext,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};