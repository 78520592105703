import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { UserProvider } from "./src/context/UserContext"; // Import UserContext

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PROMISE);

export const wrapRootElement = ({ element }) => {
  return (
    <UserProvider>
      <Elements stripe={stripePromise}>
        {element}
      </Elements>
    </UserProvider>
  );
};
