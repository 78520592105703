import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  query,
  where,
  orderBy,
  getDoc,
  updateDoc,
  setDoc,
  increment, 
  arrayUnion,
  getDocs,
  serverTimestamp
} from 'firebase/firestore';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
  signInAnonymously,
  onAuthStateChanged,
  updateEmail,
  EmailAuthProvider, 
  reauthenticateWithCredential, 
  updatePassword 
} from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';


// Firebase config here...
const firebaseConfig = {
  apiKey: process.env.GATSBY_API_KEY,
  authDomain: process.env.GATSBY_ATUH_DONAIN,
  projectId: process.env.GATSBY_PROJECT_ID,
  storageBucket: process.env.GATSBY_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_MESSAGING_BUCKET,
  appId: process.env.GATSBY_APP_ID
};

// Initialize Firebase once
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
// Collection reference
const colRef = collection(db, 'books');
const colRefC = collection(db, 'coupons' );
const colRefUser = collection(db, 'user');
const colRefTask = collection(db, 'tasklist');

// Queries
const q = query(colRef, orderBy('createdAt', 'asc'));
const c = query(colRefC, orderBy('Value', 'asc'));

export const isUserSignedIn = () => {
  return auth.currentUser !== null; // Returns true if a user is logged in
};

export const updateUserPassword = async (email, currentPassword, newPassword) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error("No user is logged in.");
  }

  try {
    // Reauthenticate the user
    const credential = EmailAuthProvider.credential(email, currentPassword);
    await reauthenticateWithCredential(user, credential);
    console.log("Reauthentication successful");

    // Update the password
    await updatePassword(user, newPassword);
    console.log("Password updated successfully.");
  } catch (error) {
    console.error("Error updating password:", error.message);
    throw error;
  }
};

export const updateUserEmail = async (oldEmail, currentPassword, newEmail) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error("No user is logged in.");
  }

  try {
    // Step 1: Reauthenticate the user
    const credential = EmailAuthProvider.credential(oldEmail, currentPassword);
    await reauthenticateWithCredential(user, credential);
    console.log("Reauthentication successful");

    // Step 2: Update email in Firebase Authentication
    await updateEmail(user, newEmail);
    console.log("Email updated in Firebase Authentication");

    // Step 3: Call Netlify function to rename Firestore documents
    const response = await fetch("/.netlify/functions/renameUserDocuments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ oldEmail, newEmail }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.error || "Failed to rename documents via serverless function.");
    }

    console.log("Firestore documents renamed successfully via serverless function.");
  } catch (error) {
    console.error("Error updating user email:", error.message);
    throw error; // Propagate the error to the caller
  }
};

export const addTask = async (task) => {

  const docRef = doc(db, 'tasklist', task.id); // use Id as docId
  try {
    // Use the generated storedUserId as the document ID
    await setDoc(docRef, task, { merge: true } );
    console.log('Task added');
  } catch (error) {
    console.log('Error adding task:', error);
  }
};export const addRegUser = async (regUser) => {
  const docRef = doc(db, 'RegUser', regUser.id);
  
  try {
    // Create initial document structure without PointBalance
    const initialData = {
      id: regUser.id,
      name: regUser.name,
      country: regUser.country,
      zipCode: regUser.zipCode,
      customerId: regUser.customerId,
      createdAt: regUser.createdAt
    };

    // First, create the document without PointBalance
    await setDoc(docRef, initialData, { merge: true });
    
    // If needed, update PointBalance in a separate operation
    // This should be done through a separate admin function
    // or cloud function to bypass security rules
    
    console.log('RegUser document created successfully');
  } catch (error) {
    console.error('Error adding RegUser:', error);
    throw error;
  }
};

export const countCoupon = async (Coupon) => {
  if(Coupon.id){
    const docRefCount = doc(db, 'CouponCount', Coupon.id); // use Id as docId
    try {
      // Use the generated storedUserId as the document ID
      await setDoc(docRefCount, Coupon, { merge: true } );
    } catch (error) {
      console.log('Error adding task:', error);
    }
  }};
export const countGlobalCoupon = async (GlobalCoupon) => {
  if(GlobalCoupon.id){
    const docRefCount = doc(db, 'coupons', GlobalCoupon.id); // use Id as docId
    try {
      // Use the generated storedUserId as the document ID
      await setDoc(docRefCount, GlobalCoupon, { merge: true } );
    } catch (error) {
      console.log('Error adding task:', error);
    }
  }};

export const useCountCoupon = () => {
  let userE
  if (typeof sessionStorage !== 'undefined') {
    userE =  sessionStorage.getItem('guestEmail'); // Default to guest email
  }
  if (auth.currentUser && !auth.currentUser.isAnonymous) {
    // If a user is logged in and not anonymous, use their email
    userE = auth.currentUser.email;
  }
  const [countCoupon, setCountCoupon] = useState([]);
  useEffect(() => {
    if(userE != null){
      const docRefD = doc(db, 'CouponCount', userE);
      const unsubscribe = docRefD && onSnapshot(docRefD, (doc) => {
        if (doc.exists()) {
          const docData = doc.data();
          setCountCoupon(docData);
        } else {
          setCountCoupon(null);
        }
      });
      
      // Cleanup function
      return () => unsubscribe();
    }
  }, [userE]);

  return countCoupon;
};

export const useGlobalCountCoupon = () => {
  const [countCoupon, setCountCoupon] = useState([]);
  useEffect(() => {
    let userE
    if (typeof sessionStorage !== 'undefined') {
      userE =  sessionStorage.getItem('guestEmail'); // Default to guest email
    }
    if (auth.currentUser && !auth.currentUser.isAnonymous) {
      // If a user is logged in and not anonymous, use their email
      userE = auth.currentUser.email;
    }
    if(userE != null){
      const docRefD = doc(db, 'coupons', userE);
      const unsubscribe = docRefD && onSnapshot(docRefD, (doc) => {
        if (doc.exists()) {
          const docData = doc.data();
          setCountCoupon(docData);
        } else {
          setCountCoupon(null);
        }
      });
      
      // Cleanup function
      return () => unsubscribe();
    }
  }, []);

  return countCoupon;
};


export const addBuyInfo = async (BInfo) => {

  const docRef = doc(db, 'BuyInfo', BInfo.id); // use Id as docId
  try {
    // Use the generated storedUserId as the document ID
    await setDoc(docRef, BInfo, { merge: true } );
    console.log('buyInfo added');
  } catch (error) {
    console.log('Error adding buyInfo:', error);
  }
};

export const changeTask = async (changeData) => {
  const docRef = doc(db, 'tasklist', changeData.id);
  await updateDoc(docRef,changeData);
};
let userId;
if (typeof sessionStorage !== 'undefined') {
  userId = sessionStorage.getItem('userId');
} else {
  // Handle the case where sessionStorage is not available
  userId = null; // or some default value
}


export const useUdata = () => {
  const [udata, setUdata] = useState(null); // Initialize udata as null

  useEffect(() => {
    let docRefU; // Declare docRefU outside of the if block
    if (userId) {
      docRefU = doc(db, 'tasklist', userId);
    }

    const unsubscribe = docRefU && onSnapshot(docRefU, (doc) => {
      if (doc.exists()) {
        const docData = doc.data();
        setUdata(docData);
      } else {
        setUdata(null);
      }
    });

    // Cleanup function
    return () => {
      if (unsubscribe) {
        unsubscribe(); // Make sure unsubscribe is defined before calling it
      }
    };
  }, [userId]); // Use userId as a dependency to run this effect when it changes

  return udata;
};



export const checkRegUdata = async (User) => {
  if (User && User.Email) { // Check if User exists and User.Email is truthy
    const docRefRU = doc(db, 'RegUser', User.Email);
    
    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(docRefRU, (doc) => {
        if (doc.exists()) {
          const docData = doc.data();
          resolve(docData);
        } else {
          // Handle case where document doesn't exist
          resolve(null); // Resolve with null if document doesn't exist
        }
      });
      
      // Cleanup function
      return () => {
        unsubscribe();
      };
    });
  } else {
    // Handle case where User or User.Email is falsy
    return Promise.reject(new Error('User or User.Email is falsy'));
  }
};


export const useCoupon = () => {
  const [coupon, setCoupon] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(c, (snapshot) => {
      const newCoupon = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setCoupon(newCoupon);
    });

    // Cleanup function
    return () => unsubscribe();
  }, []);

  return coupon;
};




export const changeUserForm = async (change) => {
  const docRef = doc(db, 'user', change.id);
  await updateDoc(docRef, {
    buystatus: true
  });
};
export const SignInAnonymously = async () => {
  try {
    const auth = getAuth();
    await signInAnonymously(auth);

    // You can add an auth state change listener here if needed
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
      } else {
        // User is signed out
        console.log('User signed out');
      }
    });

    console.log('User signed in anonymously');
  } catch (error) {
    console.log('Error signing in anonymously:', error);
    return null; // Return null in case of an error
  }
};

export const addUser = async (user) => {
  const docRef = doc(db, 'user', user.id); // use Id as docId

  try {
    await setDoc(docRef, user, { merge: true }); // use setDoc with merge option instead of addDoc
    console.log('User added');
  } catch (error) {
    console.log('Error adding user:', error);
  }
};
const AddUserId = async (user) => {
  if (user) { // only proceed when user is logged in
      await addUser({
          id: user.uid,   // added this line
          email: user.email,
          createdAt: serverTimestamp(),
      });
  } else {
      console.log('No user is logged in');
  }
};



export const addBook = async (book) => {
  const colRef = collection(db, 'books');

  try {
    await addDoc(colRef, book);
    console.log('Book added');
  } catch (error) {
    console.log('Error adding book:', error);
  }
};

export const deleteBookForm = async (book) => {
  const docRef = doc(db, 'books', book.id);

  try {
    await deleteDoc(docRef);
    console.log('Book deleted');
  } catch (error) {
    console.log('Error deleting book:', error);
  }
};

export const changeBookForm = async (book) => {
  const docRef = doc(db, 'books', book.idC);
  await updateDoc(docRef, book);
};




// signing users up
export const SignupSubmit = async ({ email, password }) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    console.log('User signed up');
    return user; // Return the user object
  } catch (error) {
    console.log('Error signing up:', error);
    return null; // Return null in case of an error
  }
};


export const UserSignOut = async () => {
  try {
    await signOut(auth);
    console.log('User signed out');
  } catch (error) {
    console.log('Error signing out:', error);
  }
};

export const LoginSubmit = async ({ emailL, passwordL }) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, emailL, passwordL);
    const user = userCredential.user;
    return user; // Make sure to return the user object
  } catch (error) {
    throw error;
  }
};

export const subscribeToAuthChanges = (callback) => {
  const unsubscribe = onAuthStateChanged(auth, callback);
  return unsubscribe;
};

export const updateStudentValidation = async (email, status = 'pending') => {
  const docRef = doc(db, 'RegUser', email); // Use the user's email as the document ID

  try {
    await updateDoc(docRef, {
      'student-validation': {
        status: status, // "pending", "validated", etc.
        date: serverTimestamp(), // Timestamp for the action
      },
    });
    console.log('Student validation status updated');
  } catch (error) {
    console.error('Error updating student validation:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export const getLastSubmissionDate = async (email) => {
  try {
    const docRef = doc(db, 'StudVerification', email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.date ? new Date(data.date) : null; // Ensure it's a Date object
    } else {
      return null; // No data found
    }
  } catch (error) {
    console.error('Error fetching last submission date:', error);
    throw error;
  }
};

export const addStudentVerification = async ({ id, verificationCode, date = null }) => {
  if (!id || !verificationCode) {
    throw new Error('Missing required fields for addStudentVerification.');
  }

  try {
    const docRef = doc(db, 'StudVerification', id);

    // Construct the base data object
    const data = {
      verificationCode,
    };

    // Add the date field only if it is provided
    if (date) {
      data.date = date; // Add the date field if it exists
    }

    // Set or update the document in Firestore
    await setDoc(docRef, data, { merge: true });

    console.log('addStudentVerification: Data successfully written to Firestore.');
  } catch (error) {
    console.error('addStudentVerification: Error writing to Firestore:', error);
    throw error;
  }
};



export const useVerificationCode = (studentEmail) => {
  const [verificationCode, setVerificationCode] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!studentEmail) return; // Skip if no email is provided

    const docRef = doc(db, 'StudVerification', studentEmail);

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          setVerificationCode(docSnap.data().verificationCode);
          setError(null); // Clear error if the document exists
        } else {
          setVerificationCode(null); // No data yet, no error
        }
      },
      (err) => {
        console.error('Error fetching verification code:', err);
        setError('Failed to fetch verification code.');
      }
    );

    // Cleanup function
    return () => unsubscribe();
  }, [studentEmail]);

  return { verificationCode, error };
};


export const verifyCode = async (studentEmail, inputCode) => {
  try {
    const docRef = doc(db, 'StudVerification', studentEmail);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const storedCode = docSnap.data().verificationCode;
      return storedCode.toString() === inputCode.toString();
    } else {
      console.error('No verification data found for email:', studentEmail);
      return false;
    }
  } catch (error) {
    console.error('Error verifying code:', error);
    throw error;
  }
};


/**
 * Update the RegUser document with points and the connected student email.
 * @param {string} userEmail - The logged-in user's email (document ID in RegUser).
 * @param {string} studentEmail - The student email being validated.
 * @param {number} points - The number of points to add.
 */
export const updateRegUserWithPoints = async (userEmail, studentEmail, points) => {
  const docRef = doc(db, 'RegUser', userEmail);

  try {
    // Fetch the current PointBalance
    const docSnap = await getDoc(docRef);

    let currentPoints = 0;

    if (docSnap.exists() && docSnap.data().PointBalance) {
      currentPoints = docSnap.data().PointBalance;
    }

    // Update the PointBalance and add the student email
    await updateDoc(docRef, {
      'student-validation': studentEmail, // Save the connected student email
      PointBalance: currentPoints + points, // Add the fetched points and new ones
    });

    console.log(`RegUser updated successfully with ${points} additional points.`);
  } catch (error) {
    console.error('Error updating RegUser:', error);
    throw error;
  }
};

/**
 * Add an entry to PointHistory
 * @param {string} email - The logged-in user's email (document ID in PointHistory).
 * @param {object} historyEntry - An object containing transaction details.
 */
export const addPointHistoryEntry = async (email, historyEntry) => {
  const docRef = doc(db, 'PointHistory', email);

  const defaultHistoryEntry = {
    date: serverTimestamp(),
    type: 'general', // Default transaction type
    amount: 0, // Default amount
    ...historyEntry, // Overwrite defaults with provided entry
  };

  try {
    await setDoc(
      docRef,
      { transactions: arrayUnion(defaultHistoryEntry) }, // Append new transaction
      { merge: true } // Ensure new transactions are added to existing document
    );
    console.log('Point history entry added successfully.');
  } catch (error) {
    console.error('Error adding point history entry:', error);
    throw error;
  }
};

export const getPointPrices = async () => {
  try {
    const collectionRef = collection(db, 'PointPrice'); // Access the collection
    const querySnapshot = await getDocs(collectionRef);

    const pointPrices = querySnapshot.docs.map((doc) => ({
      id: doc.id, // Document ID (e.g., Basic, Advanced)
      ...doc.data(), // Merge the document data (Price and Value)
    }));

    return pointPrices;
  } catch (error) {
    console.error('Error fetching point prices:', error);
    throw error;
  }
};
export const checkStudentEmailStatus = async (userEmail) => {
  try {
    const userDocRef = doc(db, 'RegUser', userEmail);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      return { 
        status: true, 
        hasValidationEmail: false 
      };
    }

    const studentEmail = userDocSnap.data()['student-validation'];
    
    if (!studentEmail) {
      return { 
        status: true, 
        hasValidationEmail: false 
      };
    }

    const studentDocRef = doc(db, 'StudVerification', studentEmail);
    const studentDocSnap = await getDoc(studentDocRef);

    if (!studentDocSnap.exists()) {
      return {
        status: false,
        hasValidationEmail: true,
        studentEmail,
        needsRevalidation: true
      };
    }

    const lastSubmissionDate = studentDocSnap.data().date 
      ? new Date(studentDocSnap.data().date) 
      : null;

    if (!lastSubmissionDate) {
      return {
        status: false,
        hasValidationEmail: true,
        studentEmail,
        needsRevalidation: true
      };
    }

    const currentDate = new Date();
    const monthsDifference = 
      (currentDate.getFullYear() - lastSubmissionDate.getFullYear()) * 12 +
      (currentDate.getMonth() - lastSubmissionDate.getMonth());

    if (monthsDifference >= 5) {
      return {
        status: false,
        hasValidationEmail: true,
        studentEmail,
        needsRevalidation: true,
        monthsDifference
      };
    }

    const nextValidationDate = new Date(lastSubmissionDate);
    nextValidationDate.setMonth(nextValidationDate.getMonth() + 5);

    return {
      status: true,
      hasValidationEmail: true,
      studentEmail,
      nextValidationDate: nextValidationDate.toISOString(),
      needsRevalidation: false
    };
  } catch (error) {
    console.error('Error checking student email status:', error);
    throw error;
  }
};


/**
 * Check and return the currently logged-in user's email.
 * @returns {Promise<string|null>} - The email of the logged-in user, or null if no user is logged in.
 */
export const getCurrentUserEmail = async () => {
  if (auth.currentUser) {
    // Return the user's email if logged in
    return auth.currentUser.email || null;
  }

  // Handle cases where auth state hasn't resolved yet
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe(); // Cleanup the listener
      if (user) {
        resolve(user.email);
      } else {
        resolve(null);
      }
    }, reject);
  });
};


export const getUserData = async () => {
  try {
    const userEmail = await getCurrentUserEmail(); // Get the logged-in user's email
    if (!userEmail) return { name: null, initials: null, country: null, zipCode: null, customerId: null, PointBalance: null};

    const docRef = doc(db, "RegUser", userEmail); // Reference the RegUser document using the email
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      const name = userData.name || null; // Assume `name` field contains the user's full name
      const initials =
        name &&
        name
          .split(" ")
          .filter(Boolean) // Split name into words, filter out empty strings
          .slice(0, 2) // Take first two words
          .map((part) => part[0].toUpperCase()) // Extract initials
          .join(""); // Join initials

      // Extract additional fields from userData
      const { country, zipCode, customerId, PointBalance } = userData;

      return { name, initials, country, zipCode, customerId, PointBalance };
    }

    return { name: null, initials: null, country: null, zipCode: null, customerId: null, PointBalance: null }; // Return null if no data found
  } catch (error) {
    console.error("Error fetching user data:", error);
    return { name: null, initials: null, country: null, zipCode: null, customerId: null, PointBalance: null }; // Handle errors gracefully
  }
};

export const getUserPointBalance = async () => {
  try {
    const userEmail = await getCurrentUserEmail(); // Get the logged-in user's email
    if (!userEmail) return null;

    const docRef = doc(db, "RegUser", userEmail); // Reference the RegUser document using the email
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      const pointBalance = userData.PointBalance || 0; // Assuming PointBalance is a number field
      return pointBalance;
    }

    return null; // Return null if no PointBalance is found
  } catch (error) {
    console.error("Error fetching user PointBalance:", error);
    return null;
  }
};

export const getPointHistory = async (email) => {
  try {
    const docRef = doc(db, "PointHistory", email); // Reference to the user's PointHistory document
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const pointHistory = docSnap.data().transactions || []; // Fetch transactions array
      return pointHistory.map((entry) => ({
        ...entry,
        date: entry.date
          ? new Date(entry.date).toISOString() // Safely handle the date field
          : null, // Use null if the date is missing
      }));
    } else {
      console.log("No point history found for user:", email);
      return []; // Return an empty array if no document exists
    }
  } catch (error) {
    console.error("Error fetching point history:", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export const getLinkHistory = async (email) => {
  try {
    const docRef = doc(db, 'LinkHistory', email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const linkHistory = docSnap.data();
      return linkHistory.Links || [];
    } else {
      console.log('No link history found for user:', email);
      return [];
    }
  } catch (error) {
    console.error('Error fetching link history:', error);
    throw error;
  }
};





export const updateUserName = async (newName) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error("No user is logged in.");
    }
    // Step 2: Update name in Firestore
    const userDocRef = doc(db, "RegUser", user.email);
    await updateDoc(userDocRef, { name: newName });
    console.log("User name updated in Firestore.");
  } catch (error) {
    console.error("Error updating user name:", error);
    throw error; // Propagate the error to the caller
  }
};

export const updateUserCountry = async (newCountry, newZipCode) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error("No user is logged in.");
    }
    // Step 2: Update name in Firestore
    const userDocRef = doc(db, "RegUser", user.email);
    await updateDoc(userDocRef, { country: newCountry, zipCode: newZipCode });
    console.log("User name updated in Firestore.");
  } catch (error) {
    console.error("Error updating user country:", error);
    throw error; // Propagate the error to the caller
  }
};